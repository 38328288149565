<!--
 * @Author: wh
 * @Date: 2020-08-10 20:02:21
 * @LastEditTime: 2021-07-01 18:24:36
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JT_Web_test\src\views\purchaseManage\materialMakeup\monthMakeup.vue
-->
<template>
    <div class="monthMakeup">
        <div class="table-con">
            <table class="custom-table">
                <thead>
                    <tr>
                        <th class="column-tit" style="width:1rem">
                            企业名称：
                        </th>
                        <th colspan="10">
                            {{ formData.enterprise_name }}
                        </th>
                        <th class="column-tit" style="width:1rem">
                            场站：
                        </th>
                        <th colspan="8">
                            <el-select
                                v-model="formData.station_name"
                                placeholder="请选择"
                                @change="supplySiteChange"
                            >
                                <el-option
                                    v-for="k in supplySites"
                                    :key="k.station_id"
                                    :label="k.station_name"
                                    :value="k.station_name"
                                >
                                </el-option>
                            </el-select>
                        </th>
                    </tr>
                    <tr>
                        <th class="column-tit">
                            合同编号：
                        </th>
                        <th colspan="10">
                            {{ formData.contract_number }}
                        </th>
                        <th class="column-tit" style="width: 1.2rem">
                            供应商名称：
                        </th>
                        <th colspan="8">
                            {{ formData.supplier_name }}
                        </th>
                    </tr>
                    <tr>
                        <th class="column-tit">
                            合同名称：
                        </th>
                        <th colspan="10">
                            {{ formData.contract_name }}
                        </th>
                        <th class="column-tit">
                        </th>
                        <th colspan="8">
                        </th>
                    </tr>
                    <tr>
                        <th class="column-tit">
                            补录时间：
                        </th>
                        <th colspan="10">
                            <el-date-picker
                                type="month"
                                v-model="supplementTime"
                                placeholder="选择月"
                                value-format="yyyy-MM"
                                @change="choseMonth"
                            >
                            </el-date-picker>
                        </th>
                        <th class="column-tit">
                            录入人：
                        </th>
                        <th colspan="8">
                            {{ formData.creator }}
                        </th>
                    </tr>
                    <tr>
                        <th colspan="11">
                            补录每月原材料采购数据情况如下：
                        </th>
                        <th colspan="9">
                            单位：吨，元
                        </th>
                    </tr>
                </thead>
            </table>
            <el-table
                :data="tableData"
                style="width: 100%"
                height="calc(100% - 2.2rem)"
            >
                <el-table-column
                    prop="type_name"
                    label="原材料名称"
                    fixed
                    width="100"
                >
                </el-table-column>
                <el-table-column
                    prop="child_type_name"
                    label="原材料子类"
                    fixed
                    width="100"
                >
                </el-table-column>
                <el-table-column
                    prop="spec_name"
                    label="原材料规格"
                    fixed
                    width="100"
                >
                </el-table-column>
                <el-table-column label="本月采购">
                    <el-table-column
                        label="采购量"
                    >
                        <template slot-scope="scope">
                            <el-input
                                v-model="scope.row.month_purchase_volume"
                                @blur="computedVolume(scope.row, 'month_purchase_volume')"
                                v-if="scope.$index<tableData.length-1"
                            ></el-input>
                            <span v-else>{{ scope.row.month_purchase_volume }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="采购单价(不含税)"
                        width="80"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.month_unit_price }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="采购单价(含税)"
                        width="80"
                    >
                        <template slot-scope="scope">
                            <!-- <el-input
                                v-model="scope.row.month_unit_price_tax"
                                v-if="scope.$index<tableData.length-1"
                                @blur="computedVolume(scope.row)"
                            ></el-input>
                            <span v-else></span> -->
                            {{ scope.row.month_unit_price_tax }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="tax_rate"
                        label="税率（%）"
                    >
                        <template slot-scope="scope">
                            <el-input
                                v-model="scope.row.tax_rate"
                                v-if="scope.$index<tableData.length-1"
                                @blur="computedVolume(scope.row)"
                            ></el-input>
                            <span v-else></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="采购金额(不含税)"
                        width="80"
                    >
                        <template slot-scope="scope">
                            <el-input
                                v-model="scope.row.month_amount"
                                v-if="scope.$index<tableData.length-1"
                                @blur="computedVolume(scope.row)"
                            ></el-input>
                            <span v-else></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="税额"
                    >
                        <template slot-scope="scope">
                            <span>{{ scope.row.tax_amount }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="采购金额(含税)"
                        width="80"
                    >
                        <template slot-scope="scope">
                            <el-input
                                v-model="scope.row.month_amount_tax"
                                v-if="scope.$index<tableData.length-1"
                                @blur="computedVolume(scope.row)"
                            ></el-input>
                            <span v-else></span>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="年累计">
                    <el-table-column
                        label="采购量 "
                    >
                        <template slot-scope="scope">
                            {{ scope.row.year_purchase_volume }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="采购均价(含税)"
                        width="80"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.average_price_tax }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="采购金额(含税)"
                        width="80"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.year_amount_tax }}
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="期末库存">
                    <el-table-column
                        label="库存量 "
                    >
                        <template slot-scope="scope">
                            <el-input
                                v-model="scope.row.ending_inventory"
                                @blur="computedStock(scope.row)"
                                v-if="scope.$index<tableData.length-1"
                            ></el-input>
                            <span v-else>{{ scope.row.ending_inventory }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="库存金额(含税)"
                        width="80"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.ending_inventory_amount_tax }}
                        </template>
                    </el-table-column>
                </el-table-column>
                <!-- <el-table-column
                    label="上月期末应付账款"
                    width="80"
                >
                    <template slot-scope="scope">
                        <el-input
                            v-model="scope.row.last_month_accounts_payable"
                            @blur="computedAccount(scope.row, 'accounts')"
                            v-if="scope.$index<tableData.length-1"
                        ></el-input>
                        <span v-else></span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="本月实付账款"
                    width="80"
                >
                    <template slot-scope="scope">
                        <el-input
                            v-model="scope.row.this_month_actual_payment"
                            @blur="computedAccount(scope.row, 'accounts')"
                            v-if="scope.$index<tableData.length-1"
                        ></el-input>
                        <span v-else></span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="本月预付账款"
                    width="80"
                >
                    <template slot-scope="scope">
                        {{ scope.row.this_month_prepayment }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="this_month_accounts_payable"
                    label="本月期末应付余额"
                    width="80"
                >
                    <template slot-scope="scope">
                        {{ scope.row.this_month_accounts_payable }}
                    </template>
                </el-table-column> -->
            </el-table>
        </div>
        <div class="btn-con">
            <button class="save" @click="handleSave">
                保存
            </button>
            <button class="Close" @click="handleColse">
                关闭
            </button>
        </div>
    </div>
</template>

<script>
import store from '@/store';
export default {
    components: {},
    props: {
        extr: {
            type: Object,
        },
    },
    data() {
        return {
            formData: {},
            tableData: [],
            sumRow: {
                type_name: '合计',
                child_type_name: '',
                spec_name: '',
                month_purchase_volume: '',
                month_unit_price: '',
                month_unit_price_tax: '',
                tax_amount: '',
                tax_rate: '',
                month_amount: '',
                month_amount_tax: '',
                year_purchase_volume: '',
                average_price_tax: '',
                year_amount_tax: '',
                ending_inventory: '',
                ending_inventory_amount_tax: '',
                last_month_accounts_payable: '',
                this_month_accounts_payable: '',
                this_month_actual_payment: '',
                this_month_prepayment: '',
            },
            // 合同编号
            contract_number: '',
            // 场站
            supplySites: [],
            // 场站id
            station_id: '',
            // 补录月份
            supplementTime: '',
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {
        this.initData();
    },
    methods: {
        // 保存
        async handleSave() {
            if (this.tableData.length > 0) {
                const sumData = this.tableData[this.tableData.length - 1];
                this.formData.month_purchase_volume_total = sumData.month_purchase_volume;
                this.formData.tax_amount_total = sumData.tax_amount;
                this.formData.month_amount_total = sumData.month_amount;
                this.formData.month_amount_tax_total = sumData.month_amount_tax;
                this.formData.year_purchase_volume_total = sumData.year_purchase_volume;
                this.formData.year_amount_tax_total = sumData.year_amount_tax;
                this.formData.ending_inventory_total = sumData.ending_inventory;
                this.formData.ending_inventory_amount_tax_total = sumData.ending_inventory_amount_tax;
                const tableData = JSON.parse(JSON.stringify(this.tableData));
                tableData.pop();
                this.formData.PSMOutPutInfo = tableData;
            } else {
                this.formData.PSMOutPutInfo = [];
            }
            if (this.extr.handleType === 'new') {
                const res = await this.addSupplementMonthApi(this.formData);
                if (res) {
                    this.$message.success('保存成功');
                    store.state.currentOpenList.forEach(item => {
                        item.tableObj.updateTable();
                    });
                    this.$parent.hide();
                }
            } else {
                const res = await this.modifySupplementDayApi(this.formData);
                if (res) {
                    this.$message.success('修改成功');
                    store.state.currentOpenList.forEach(item => {
                        item.tableObj.updateTable();
                    });
                    this.$parent.hide();
                }
            }

        },
        // 关闭
        handleColse() {
            this.$parent.hide();
        },
        // 初始化数据
        initData() {
            if (this.extr.QueryString) {
                this.contract_number = this.extr.QueryString.split('=')[1];
            }
            if (this.extr.handleType === 'new') {
                this.getContractMonth();
                this.getSupplySites();
            } else {
                this.getContractMonthByid(this.extr.id);
            }
        },
        // 根据id拉取
        async getContractMonthByid(id) {
            const res = await this.getContractMonthByidApi(id);
            if (res) {
                this.formData = res;
                if (!res.PSMOutPutInfo) {
                    res.PSMOutPutInfo = [];
                }
                this.tableData = res.PSMOutPutInfo;
                this.sumRow.month_purchase_volume = this.formData.month_purchase_volume_total;
                this.sumRow.tax_amount = this.formData.tax_amount_total;
                this.sumRow.month_amount = this.formData.month_amount_total;
                this.sumRow.month_amount_tax = this.formData.month_amount_tax_total;
                this.sumRow.year_purchase_volume = this.formData.year_purchase_volume_total;
                this.sumRow.year_amount_tax = this.formData.year_amount_tax_total;
                this.sumRow.ending_inventory = this.formData.ending_inventory_total;
                this.sumRow.ending_inventory_amount_tax = this.formData.ending_inventory_amount_tax_total;
                this.formData.supplement_month = this.formData.supplement_month < 10 ? '0'
                + this.formData.supplement_month : this.formData.supplement_month;
                this.supplementTime = this.formData.supplement_year + '-' + this.formData.supplement_month;
                this.contract_number = this.formData.contract_number;
                if (this.tableData.length > 0) {
                    this.tableData.push(this.sumRow);
                }
                this.getSupplySites();
            }
        },
        // 拉取合同信息
        async getContractMonth() {
            const res = await this.getContractMonthApi();
            if (res) {
                this.formData = res;
                this.formData.creator = this.$takeTokenParameters('Name');
                if (!res.PSMOutPutInfo) {
                    res.PSMOutPutInfo = [];
                }
                this.tableData = res.PSMOutPutInfo;
                // this.tableData.map(item => {
                //     // 采购单价（不含税）=采购单价（含税）/（1+税率%）
                //     // if (item.month_unit_price_tax) {
                //     //     if (item.tax_rate) {
                //     //         item.month_unit_price = (Number(item.month_unit_price_tax) / (1 + Number(item.tax_rate) / 100)).toFixed(2);
                //     //     } else {
                //     //         item.month_unit_price = (Number(item.month_unit_price_tax) / 1).toFixed(2);
                //     //     }
                //     // }
                //     item.month_unit_price_tax = null;
                // });
                this.sumRow.month_purchase_volume = this.formData.month_purchase_volume_total;
                this.sumRow.tax_amount = this.formData.tax_amount_total;
                this.sumRow.month_amount = this.formData.month_amount_total;
                this.sumRow.month_amount_tax = this.formData.month_amount_tax_total;
                this.sumRow.year_purchase_volume = this.formData.year_purchase_volume_total;
                this.sumRow.year_amount_tax = this.formData.year_amount_tax_total;
                this.sumRow.ending_inventory = this.formData.ending_inventory_total;
                this.sumRow.ending_inventory_amount_tax = this.formData.ending_inventory_amount_tax_total;
                if (this.tableData.length > 0) {
                    this.tableData.push(this.sumRow);
                }
            }
        },
        // 切换场站-重新拉取原材料列表
        async getContractMonthStation() {
            const res = await this.getContractMonthApi();
            if (res) {
                if (!res.PSMOutPutInfo) {
                    res.PSMOutPutInfo = [];
                }
                this.tableData = res.PSMOutPutInfo;
                this.sumRow.month_purchase_volume = res.month_purchase_volume_total;
                this.sumRow.tax_amount = res.tax_amount_total;
                this.sumRow.month_amount = res.month_amount_total;
                this.sumRow.month_amount_tax = res.month_amount_tax_total;
                this.sumRow.year_purchase_volume = res.year_purchase_volume_total;
                this.sumRow.year_amount_tax = res.year_amount_tax_total;
                this.sumRow.ending_inventory = res.ending_inventory_total;
                this.sumRow.ending_inventory_amount_tax = res.ending_inventory_amount_tax_total;
                this.tableData.push(this.sumRow);
            }
        },
        // 拉取场站
        async getSupplySites() {
            const res = await this.getSupplySitesApi();
            if (res) {
                this.supplySites = res;
            }
        },
        // 下拉选取场站
        supplySiteChange(value) {
            const data = this.supplySites.filter(item => item.station_name === value);
            this.formData.station_name = data[0].station_name;
            this.formData.station_id = data[0].station_id;
            this.$forceUpdate();
            if (this.formData.station_id && this.supplementTime) {
                this.getContractMonthStation();
            }
        },
        // 选取月份
        choseMonth() {
            this.formData.supplement_year = this.supplementTime.split('-')[0];
            this.formData.supplement_month = this.supplementTime.split('-')[1];
            if (this.formData.station_id && this.supplementTime) {
                this.getContractMonthStation();
            }
        },
        addSupplementMonthApi(data) {
            const path = '/interfaceApi/procurement/purchase_supplement/add_supplement_month';
            return this.$axios.$post(path, data, { defEx: true });
        },
        modifySupplementDayApi(data) {
            const path = '/interfaceApi/procurement/purchase_supplement/modify_supplement_month';
            return this.$axios.$put(path, data, { defEx: true });
        },
        getContractMonthByidApi(id) {
            const path = '/interfaceApi/procurement/purchase_supplement/get_supplement_month_by_id?id=' + id;
            return this.$axios.$get(path, { defEx: true });
        },
        getSupplySitesApi() {
            const path = '/interfaceApi/procurement/purchase_supplement/get_station_list?contract_number=' + this.contract_number;
            return this.$axios.$get(path, { defEx: true });
        },
        getContractMonthApi() {
            let path = '/interfaceApi/procurement/purchase_supplement/get_supplement_contract_month?contract_number=' + this.contract_number;
            if (this.formData.station_id) {
                path += '&station_id=' + this.formData.station_id;
            }
            if (this.supplementTime) {
                path += '&date=' + this.supplementTime;
            }
            return this.$axios.$get(path, { defEx: true });
        },
        // 计算与方量相关
        computedVolume(rowData) {
            // // 采购单价（不含税）=采购单价（含税）/（1+税率%）
            // if (rowData.month_unit_price_tax) {
            //     if (rowData.tax_rate) {
            //         rowData.month_unit_price = (Number(rowData.month_unit_price_tax) / (1 + Number(rowData.tax_rate) / 100)).toFixed(2);
            //     } else {
            //         rowData.month_unit_price = (Number(rowData.month_unit_price_tax) / 1).toFixed(2);
            //     }
            // }
            // // 本月采购金额（不含税）=采购单价（不含税）*采购量
            // if (rowData.month_unit_price && rowData.month_purchase_volume) {
            //     rowData.month_amount = (Number(rowData.month_unit_price) * Number(rowData.month_purchase_volume)).toFixed(2);
            // }
            // // 本月采购金额（含税）=采购单价（含税）*采购量
            // if (rowData.month_unit_price_tax && rowData.month_purchase_volume) {
            //     rowData.month_amount_tax = (Number(rowData.month_unit_price_tax) * Number(rowData.month_purchase_volume)).toFixed(2);
            // }


            // 采购单价（不含税）=采购金额（不含税）/采购量，保留2位小数
            if (rowData.month_amount && rowData.month_purchase_volume) {
                if (Number(rowData.month_purchase_volume) !== 0) {
                    rowData.month_unit_price = (Number(rowData.month_amount) / Number(rowData.month_purchase_volume)).toFixed(2);
                } else {
                    rowData.month_unit_price = 0;
                }
            }
            // 采购单价（含税）=采购金额（含税）/采购量，保留2位小数
            if (rowData.month_amount_tax && rowData.month_purchase_volume) {
                if (Number(rowData.month_purchase_volume) !== 0) {
                    rowData.month_unit_price_tax = (Number(rowData.month_amount_tax) / Number(rowData.month_purchase_volume)).toFixed(2);
                } else {
                    rowData.month_unit_price_tax = 0;
                }
            }
            // 税额=采购金额（含税）-采购金额（不含税）
            if (rowData.month_amount_tax && rowData.month_amount) {
                rowData.tax_amount = (Number(rowData.month_amount_tax) - Number(rowData.month_amount)).toFixed(2);
            }

            // // 税额=（采购单价（含税）-采购单价（不含税））*采购量
            // if (rowData.month_unit_price_tax && rowData.month_unit_price && rowData.month_purchase_volume) {
            //     rowData.tax_amount = ((Number(rowData.month_unit_price_tax) - Number(rowData.month_unit_price))
            //     * Number(rowData.month_purchase_volume)).toFixed(2);
            // }
            rowData = this.computedAccountFn(rowData);
            // 年累计采购量=本月采购量+上月年累计采购量；
            if (!rowData.month_purchase_volume) {
                rowData.month_purchase_volume = 0;
            }
            if (!rowData.last_month_year_purchase_volume) {
                rowData.last_month_year_purchase_volume = 0;
            }
            rowData.year_purchase_volume = Number(rowData.month_purchase_volume) + Number(rowData.last_month_year_purchase_volume);
            // 年累计采购金额（含税）= 本月采购金额（含税）+ 上月年累计采购金额（含税）；
            if (!rowData.month_amount_tax) {
                rowData.month_amount_tax = 0;
            }
            if (!rowData.last_month_year_amount_tax) {
                rowData.last_month_year_amount_tax = 0;
            }
            rowData.year_amount_tax = Number(rowData.month_amount_tax) + Number( rowData.last_month_year_amount_tax);
            // 年累计采购均价（含税）= 年累计采购金额（含税）/ 年累计采购量
            if (!rowData.year_amount_tax) {
                rowData.year_amount_tax = 0;
            }
            if (!rowData.year_purchase_volume) {
                rowData.year_purchase_volume = 0;
            }
            if (rowData.year_purchase_volume !== 0) {
                rowData.average_price_tax = (Number(rowData.year_amount_tax) / Number(rowData.year_purchase_volume)).toFixed(2);
            }
            this.computedSum();
        },
        // 计算与库存相关
        computedStock(rowData) {
            // 库存金额  (含税）=库存量*年累计采购均价（含税）
            if (rowData.ending_inventory && rowData.average_price_tax) {
                rowData.ending_inventory_amount_tax = (Number(rowData.ending_inventory) * Number(rowData.average_price_tax)).toFixed(2);
            }
            this.computedSum();
        },
        // 计算与账款相关
        computedAccount(rowData) {
            rowData = this.computedAccountFn(rowData);
            this.computedSum();
        },
        // 账款具体计算
        computedAccountFn(rowData) {
            // 本月期末应付余额=上月期末应付账款+本月采购金额+税额-本月实付账款
            if (!rowData.last_month_accounts_payable) {
                rowData.last_month_accounts_payable = 0;
            }
            if (!rowData.month_amount) {
                rowData.month_amount = 0;
            }
            if (!rowData.tax_amount) {
                rowData.tax_amount = 0;
            }
            if (!rowData.this_month_actual_payment) {
                rowData.this_month_actual_payment = 0;
            }
            rowData.this_month_accounts_payable = (Number(rowData.last_month_accounts_payable) + Number(rowData.month_amount)
            + Number(rowData.tax_amount) - Number(rowData.this_month_actual_payment)).toFixed(2);
            // 如果本月期末应付余额<0，本月预付金额=-本月期末应付余额；如果本月期末应付余额≥0，本月预付金额=0
            if (Number(rowData.this_month_accounts_payable) < 0) {
                rowData.this_month_prepayment = rowData.this_month_accounts_payable;
            } else {
                rowData.this_month_prepayment = 0;
            }
            return rowData;
        },
        // 合计
        computedSum() {
            const sumData = {
                type_name: '合计',
                child_type_name: '',
                spec_name: '',
                month_purchase_volume: 0,
                month_unit_price: '',
                month_unit_price_tax: '',
                tax_amount: 0,
                tax_rate: '',
                month_amount: 0,
                month_amount_tax: 0,
                year_purchase_volume: 0,
                average_price_tax: '',
                year_amount_tax: 0,
                ending_inventory: 0,
                ending_inventory_amount_tax: 0,
                last_month_accounts_payable: '',
                this_month_accounts_payable: '',
                this_month_actual_payment: '',
                this_month_prepayment: '',
                subtotal: '',
            };
            this.tableData.map((item, index) => {
                if (index < this.tableData.length - 1) {
                    if (!item.month_purchase_volume) {
                        item.month_purchase_volume = 0;
                    }
                    if (!item.month_amount) {
                        item.month_amount = 0;
                    }
                    if (!item.month_amount_tax) {
                        item.month_amount_tax = 0;
                    }
                    if (!item.tax_amount) {
                        item.tax_amount = 0;
                    }
                    if (!item.year_purchase_volume) {
                        item.year_purchase_volume = 0;
                    }
                    if (!item.year_amount_tax) {
                        item.year_amount_tax = 0;
                    }
                    if (!item.ending_inventory) {
                        item.ending_inventory = 0;
                    }
                    if (!item.ending_inventory_amount_tax) {
                        item.ending_inventory_amount_tax = 0;
                    }
                    sumData.month_purchase_volume = (Number(sumData.month_purchase_volume) + Number(item.month_purchase_volume)).toFixed(2);
                    sumData.month_amount = (Number(sumData.month_amount) + Number(item.month_amount)).toFixed(2);
                    sumData.month_amount_tax = (Number(sumData.month_amount_tax) + Number(item.month_amount_tax)).toFixed(2);
                    sumData.tax_amount = (Number(sumData.tax_amount) + Number(item.tax_amount)).toFixed(2);
                    sumData.year_purchase_volume = (Number(sumData.year_purchase_volume) + Number(item.year_purchase_volume)).toFixed(2);
                    sumData.year_amount_tax = (Number(sumData.year_amount_tax) + Number(item.year_amount_tax)).toFixed(2);
                    sumData.ending_inventory = (Number(sumData.ending_inventory) + Number(item.ending_inventory)).toFixed(2);
                    sumData.ending_inventory_amount_tax = (Number(sumData.ending_inventory_amount_tax)
                    + Number(item.ending_inventory_amount_tax)).toFixed(2);
                }
            });
            this.tableData[this.tableData.length - 1] = { ...sumData };
        },
    },
};
</script>
<style lang="stylus">
.monthMakeup
    padding .2rem
    height 100%
    overflow hidden
    .table-con
        height calc(100% - .8rem)
        .custom-table
            border 1px solid #D7D7D7
            border-spacing 0px
            border-collapse collapse
            width 100%
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button
                -webkit-appearance none !important
            .pump-select
                .el-select
                    width 1rem
                    input
                        border 1px solid #DCDFE6 !important
                        height .32rem
                        line-height .32rem
                    .el-input__icon
                        line-height .32rem!important
            th
                text-align left
                font-weight normal
            .el-select
                width 100%
            .text-center
                text-align center
            td,th
                border 1px solid #D7D7D7
                border-collapse collapse
                height .42rem
                font-size .16rem;
                padding-left .1rem
                input
                    width 100%
                    height 100%
                    font-size 0.16rem
                    height 0.38rem
                    line-height 0.4rem
                    border none
                .el-input__inner
                    border none
                .danger
                    span
                        color #E60000
            td
                .el-input__inner
                    padding 0
            .column-tit
                background #f6f8fc
                color #022782
            .table-tit
                background #edf0f5
                text-align center
                font-size .16rem
                font-weight bold
                color #022782
        .el-table
            margin-top .1rem
            thead
                &.is-group
                    th
                        background #f6f8fc
                        border-right 1px solid #D7D7D7
                        .cell
                            font-size .16rem
                            color #022782
                            font-weight normal
            .is-leaf
                border-bottom 1px solid #D7D7D7
            th
                border-right 1px solid #D7D7D7
                border-bottom 1px solid #D7D7D7
                text-align center
            td
                border-right 1px solid #D7D7D7
                border-bottom 1px solid #D7D7D7
                padding .03rem
                text-align center
                .el-input__inner
                    padding 0 .03rem
                    height .32rem
                    line-height .32rem
                    text-align center
            tr
                &:last-child
                    td
                        background #f5f7fa
                        color #823802
                        &:first-child
                            background #f5f2ed
                            color #823802
            .cell
                padding-left 0
                padding-right 0
        .el-table--border, .el-table--group
            border 1px solid #D7D7D7
    .btn-con
        text-align center
        position absolute
        width calc(100% - .4rem)
        bottom .2rem
        button
            width  2.6rem
            height 0.5rem
            font 0.2rem '微软雅黑'
            margin 0 0.15rem
            border none
            cursor pointer
            &.save
                color #fff
                background #1577d2
            &.Close
                color #1577d2
                background #fff
                border 1px solid #1577d2
</style>
